import React from 'react'

import Layout from '../global/Layout'
import { MsgStyled } from '../common/common'

const NotFoundPage = () => (
  <Layout>
    <MsgStyled>
      Not found: You just hit a route that doesn&#39;t exist... the sadness.
    </MsgStyled>
  </Layout>
)

export default NotFoundPage
